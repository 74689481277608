// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/common-section.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".common__section {\n  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  background-size: cover;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 100px 0;\n}\n.common__section h1 {\n  color: #fff;\n  font-weight: 600;\n}\n\n@media screen and (max-width: 768px) {\n  .common__section {\n    padding: 70px 0;\n  }\n  .common__section h1 {\n    font-size: 1.4rem;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/UI/CommonSection/commonSection.scss"],"names":[],"mappings":"AAAA;EACC,oIAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACD;AACC;EACC,WAAA;EACA,gBAAA;AACF;;AAEA;EACC;IACC,eAAA;EACA;EAAA;IACC,iBAAA;EAED;AACF","sourcesContent":[".common__section {\n\tbackground: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../../assets/images/common-section.jpg') no-repeat center center;\n\tbackground-size: cover;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding: 100px 0;\n\n\th1 {\n\t\tcolor: #fff;\n\t\tfont-weight: 600;\n\t}\n}\n@media screen  and (max-width:768px){\n\t.common__section {\n\t\tpadding: 70px 0;\n\t\th1 {\n\t\t\tfont-size: 1.4rem;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
